import React, { Component } from 'react'
import Camera from '../../components/camera/Camera';

export class CameraContainer extends Component {

  handleGalleryImg = (imgData) => {
    this.props.setGallery(imgData);
    this.props.setImgGallery(imgData);
  }

  handleErr = (message) => {
    this.props.setErr(message);
  }
  
  componentDidMount(){
    const {currentPage} = this.props;
    if (currentPage !== '/camera'){
      window.location.replace('/');
    }
    this.props.setErr("");
    // this.props.setCommonLoading(true);
    // this.props.setCameraStart(true);
  }

  componentWillUnmount(){
    this.props.setCameraStart(false);
  }

  handleCameraOption = (newVal) => {
    this.props.setCameraOption(newVal);
  }

  render() {
    const {err,camera_option,order_receiving,is_start} = this.props;
    const {imgGallery} = order_receiving;
    console.log("order_receiving",order_receiving)
    return <Camera 
            handleGalleryImg={this.handleGalleryImg} 
            imgGallery={imgGallery}
            handleErr = {this.handleErr}
            err = {err}
            handleCameraOption = {this.handleCameraOption}
            camera_option = {camera_option}
            is_start = {is_start}
          />
  }
}

export default CameraContainer;