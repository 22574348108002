import { axios } from "../../helpers/axios";
import { GET_PHOTO_LIST_URL,SEND_MAIL_URL,UPDATE_BC_STATUS } from "./photoConstants";

export const api_get_photo_list_url = async (params) => {
    return axios.get(GET_PHOTO_LIST_URL,{params});
};

export const api_get_photo_details_url = async (orderDetailID) => {
    return axios.get(GET_PHOTO_LIST_URL + '/' + orderDetailID);
};

export const api_send_mail_url = async (params,data) => {
    return axios.post(SEND_MAIL_URL,data,{params});
};

export const api_update_bc_status = async (data) => {
    return axios.patch(UPDATE_BC_STATUS,data);
};