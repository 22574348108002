import {createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen : false,
  snackbar: {
    open: false,
    message: "",
    severity: "info",
  },
  orderReceiving : {
    scanType : '',
    selectedActivity : null,
    selectedUseCase : null,
    referenceNo : null,
    order_details : null,
    imgGallery : [],
    photoLink : null,
    bc_update : false
  },
  is_image_loading : false,
  is_common_loading : false,
  is_admin : false
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSnackBar: (state, action) => {
      state.snackbar = action.payload;
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setSelectedActivity : (state,action) => {
      state.orderReceiving.selectedActivity = action.payload;
    },
    setSelectedUseCase : (state,action) => {
      state.orderReceiving.selectedUseCase = action.payload;
    },
    setScanType : (state,action) => {
      state.orderReceiving.scanType = action.payload;
    },
    setReferenceNo : (state,action) => {
      state.orderReceiving.referenceNo = action.payload;
    },
    setOrderDetails : (state,action) => {
      state.orderReceiving.order_details = action.payload;
    },
    setImgGallery : (state,action) => {
      state.orderReceiving.imgGallery = action.payload === null ? [] : [action.payload,...state.orderReceiving.imgGallery];
    },
    removeUploadedGalleryImg : (state,action) => {
      state.orderReceiving.imgGallery = state.orderReceiving.imgGallery.filter((img,idx)=> img.id !== action.payload);
    },
    updateGalleryImgStatus : (state,action) => {
      const {id,newStatus} = action.payload;
      state.orderReceiving.imgGallery = state.orderReceiving.imgGallery.map(img => img.id === id ? ({...img,'is_uploaded' : newStatus}) : img);
    },
    setPhotoLink : (state,action) => {
      state.orderReceiving.photoLink = action.payload;
    },
    setImageLoading : (state,action) => {
      state.is_image_loading = action.payload;
    },
    setCommonLoading : (state,action) => {
      state.is_common_loading = action.payload;
    },
    setIsAdmin : (state,action) => {
      state.is_admin = action.payload;
    },
    setBcUpdate : (state,action) => {
      state.orderReceiving.bc_update = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSnackBar,
  setIsOpen,
  setSelectedActivity,
  setSelectedUseCase,
  setScanType,
  setReferenceNo,
  setOrderDetails,
  setImgGallery,
  removeUploadedGalleryImg,
  updateGalleryImgStatus,
  setPhotoLink,
  setImageLoading,
  setCommonLoading,
  setIsAdmin,
  setBcUpdate
} = common.actions;

export default common.reducer;