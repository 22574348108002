import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
    removeUploadedGalleryImg,
    updateGalleryImgStatus,
    setPhotoLink,
    setImageLoading,
    setImgGallery,
    setReferenceNo,
    setScanType,
    setOrderDetails,
    setSelectedUseCase,
    setSelectedActivity,
  } from "../../store/common/commonSlice";
import { createPhotoList } from "../../store/camera/cameraSlice";

const mapStateToProps = (state) => {
  const {
    currentPage
  } = state.home;

  const {
    orderReceiving,
    is_image_loading
  } = state.common;

  return {
    currentPage : currentPage,
    order_receiving : orderReceiving,
    is_image_loading : is_image_loading
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            removeUploadedGalleryImg,
            updateGalleryImgStatus,
            setPhotoLink,
            setImageLoading,
            createPhotoList,
            setImgGallery,
            setReferenceNo,
            setScanType,
            setOrderDetails,
            setSelectedUseCase,
            setSelectedActivity
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;