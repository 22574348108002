import React, { Component } from 'react'
import OrderDetails from '../../components/scan/orderDetails';

export class OrderDetailsContainer extends Component {
  
  componentDidMount(){
    const {currentPage} = this.props;
    if (currentPage !== '/order-details'){
      window.location.replace('/');
    }
  }

  callUseCaseList = () => {
    this.props.getUseCaseList();
   }

   handleSelectedActivity = async (selectedActivity) => {
    const {id,bc_update} = selectedActivity;
    const {navigate} = this.props;
    await this.props.setSelectedActivity(id);
    await this.props.setBcUpdate(bc_update);
    await this.props.setPage('/camera');
    navigate('/camera');
  }

   handleSelectedUseCase = (selectedUseCase) => {
    this.props.setSelectedUseCase(selectedUseCase);
  }

  render() {
    const {use_case_list,order_receiving} = this.props;
    const {selectedUseCase,selectedActivity,order_details} = order_receiving;
    console.log(order_receiving)

    return <OrderDetails 
              order_details={order_details} 
              callUseCaseList={this.callUseCaseList} 
              use_case_list={use_case_list} 
              handleSelectedActivity = {this.handleSelectedActivity}
              handleSelectedUseCase = {this.handleSelectedUseCase}
              selected_use_case = {selectedUseCase}
              selected_activity = {selectedActivity}
          />
  }
}

export default OrderDetailsContainer;