import React from 'react'
import { useNavigate } from 'react-router-dom'
import { 
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnLink,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';
import * as yup from "yup";
import { useFormik } from "formik";

const SendMail = (props) => {
  const {photo_data,handleSendMail,handleCurrentPage} = props;
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    use_case : yup.string().required('please enter use case.'),
    activity : yup.string().required('please enter activity.').trim(),
    from_email : yup.string().required('please enter from email.').trim(),
    email_to : yup.string().required('please enter email to.').trim(),
    signature_url : yup.string().nullable().trim(),
    subject : yup.string().required('please enter email subject.').trim(),
    email_body : yup.string().required('please enter email body.').trim(),
  });

  const onSubmit = async (values) => {
    await handleSendMail(values);
    await handleCurrentPage('/photo-list');
    navigate('/photo-list');
  };

  const formik = useFormik({
    enableReinitialize : true,
    initialValues : {
      order_detail_id : photo_data?.order_detail_id,
      use_case : photo_data?.use_case?.name,
      activity : photo_data?.activity?.name,
      from_email : photo_data?.from_email,
      email_to : photo_data?.email_to,
      subject : photo_data?.subject,
      signature_url : photo_data?.signature_url,
      email_body : photo_data?.body
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let tmpPhotoData = {...values};
      tmpPhotoData.use_case = photo_data.use_case.id;
      tmpPhotoData.activity = photo_data.activity.id;
      onSubmit(tmpPhotoData);
    }
  });

  const handleCancel = async () => {
    await handleCurrentPage('/photo-list');
    navigate('/photo-list');
  }

  return (
    <section className='mx-2'>
      <div className='space-y-1'>
        <div className='bg-[#303030] rounded-lg text-center text-white text-sm py-1 font-medium'>Send Email</div>
        <form className='space-y-1' onSubmit={formik.handleSubmit}>
          <div className='rounded-lg bg-[#303030] py-1 px-3 flex justify-between items-center space-x-2'>
            <div>
              <label className='text-[#858182] text-sm font-medium'>Use Case</label>
              <div>
                <input
                  type="text"
                  disabled
                  name="use_case"
                  value={formik.values.use_case}
                  className="bg-[#757575] py-1 px-2 block w-full border-gray-200 rounded-sm text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                ></input>
              </div>
              <p 
                  className='text-red-600 text-start text-xs'
                >
                  {formik.touched.use_case && formik.errors.use_case}
                </p>
            </div>
            <div>
              <label className='text-[#858182] text-sm font-medium'>Activity</label>
              <div>
                <input
                  type="text"
                  disabled
                  name="activity"
                  value={formik.values.activity}
                  className="bg-[#757575] py-1 px-2 block w-full border-gray-200 rounded-sm text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                ></input>
                <p 
                  className='text-red-600 text-start text-xs'
                >
                  {formik.touched.activity && formik.errors.activity}
                </p>
              </div>
            </div>
          </div>
          <div className='rounded-lg bg-[#303030] py-1 px-3'>
            <div>
              <label className='text-[#858182] text-sm font-medium'>From Email</label>
              <div>
                <input
                  type="text"
                  name="from_email"
                  disabled
                  value={formik.values.from_email}
                  onChange={formik.handleChange("from_email")}
                  onBlur={formik.handleBlur("from_email")}
                  className="bg-[#757575] py-1 px-2 block w-full border-gray-200 rounded-sm text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                ></input>
                <p 
                  className='text-red-600 text-start text-xs'
                >
                  {formik.touched.from_email && formik.errors.from_email}
                </p>
              </div>
            </div>
          </div>
          <div className='rounded-lg bg-[#303030] py-1 px-3'>
            <div>
              <label className='text-[#858182] text-sm font-medium'>Email To</label>
              <div>
                <input
                  type="text"
                  name="email_to"
                  value={formik.values.email_to}
                  onChange={formik.handleChange("email_to")}
                  onBlur={formik.handleBlur("email_to")}
                  className="bg-[#757575] py-1 px-2 block w-full border-gray-200 rounded-sm text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                ></input>
                <p 
                  className='text-red-600 text-start text-xs'
                >
                  {formik.touched.email_to && formik.errors.email_to}
                </p>
              </div>
            </div>
          </div>
          <div className='rounded-lg bg-[#303030] py-3 px-3 flex flex-col space-y-2'>
            <div>
              <label className='text-[#858182] text-sm font-medium'>Email Subject</label>
              <div>
                <input
                  type="text"
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange("subject")}
                  onBlur={formik.handleBlur("subject")}
                  className="bg-[#757575] py-1 px-2 block w-full border-gray-200 rounded-sm text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                ></input>
                <p 
                  className='text-red-600 text-start text-xs'
                >
                  {formik.touched.subject && formik.errors.subject}
                </p>
              </div>
            </div>
            <div>
              <label className='text-[#858182] text-sm font-medium'>Signature Url</label>
              <div>
                <input
                  type="text"
                  name="signature_url"
                  disabled
                  value={formik.values.signature_url}
                  onChange={formik.handleChange("signature_url")}
                  onBlur={formik.handleBlur("signature_url")}
                  className="bg-[#757575] py-1 px-2 block w-full border-gray-200 rounded-sm text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                ></input>
              </div>
            </div>
            <div>
              <label className='text-[#858182] text-sm font-medium'>Email Body</label>
              <EditorProvider>
                <Editor
                  name='email_body'
                  value={formik.values.email_body}
                  onChange={formik.handleChange("email_body")}
                  onBlur={formik.handleBlur("email_body")}
                  containerProps={{ style: { minHeight : '140px',maxHeight:'140px',overflowY:'auto',border:'none',borderRadius:'0.125rem'} }}

                >
                  <Toolbar>
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline/>
                    <BtnLink/>
                  </Toolbar>
                </Editor>
              </EditorProvider>
              <p 
                className='text-red-600 text-start text-xs'
              >
                {formik.touched.email_body && formik.errors.email_body}
              </p>
            </div>                            
          </div>
          <div className='flex justify-center items-center py-3 space-x-8'>
            <button
              type="submit" 
              className="text-md px-5 py-1 font-semibold bg-[#303030] text-white rounded-lg shadow-sm"
            >Send</button>
            <button
              onClick={handleCancel}
              type="button" 
              className="text-md px-5 py-1 font-semibold bg-[#303030] text-white rounded-lg shadow-sm"
            >Cancel</button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default SendMail