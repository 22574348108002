import Routes from "./routes";
import {MsalProvider} from '@azure/msal-react'

function App({msalInstance}) {
  return (
    <MsalProvider instance={msalInstance}>
      <div className="lg:w-[50%] lg:h-[95%] xl:w-[35%] md:w-full md:h-full w-full h-full bg-black overflow-y-auto">
        <Routes/>
      </div>
    </MsalProvider>
  );
}

export default App;
