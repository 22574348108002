import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
    setCommonLoading
  } from "../../store/common/commonSlice";
import { getUseCaseList, setPage } from "../../store/home/homeSlice";
import { createActivity,getActivityDetails,editActivity,deleteActivity,getActivitiesList, setSearchParams, setSearchValue, goToNextPage, goToPrevPage } from "../../store/activity/activitySlice";

const mapStateToProps = (state) => {
  const {
    currentPage,
    use_case_list
  } = state.home;

  // const {
  // } = state.common;

  const {
    activity_details,
    activity_list,
    page,
    totalPages,
    totalItems,
    qs,
    limit,
    searchValue,
  } = state.activity;

  return {
    currentPage : currentPage,
    use_case_list : use_case_list,
    activity_details,
    activity_list,
    page,
    totalPages,
    totalItems,
    qs,
    limit,
    searchValue
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            setCommonLoading,
            setPage,
            getUseCaseList,
            createActivity,
            getActivitiesList,
            getActivityDetails,
            editActivity,
            deleteActivity,
            setSearchParams,
            setSearchValue,
            goToNextPage,
            goToPrevPage,
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;