import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PhotoTable = (props) => {
    const { photo_details,is_photo_loading,handlePhotoDetails,photo_data,handleCurrentPage,updateBc,handleEditBcStatus} = props;
    const navigate = useNavigate();

    const handlePhotoSend = async (orderDetailID) => {
        let res = await handlePhotoDetails(orderDetailID);
    };

    const handleUpdateBc = async (ref_no, usecase_id, photo_link, order_detail_id) => {
        let res = await updateBc(ref_no, usecase_id, photo_link);
        const payload = {
            "order_detail_id": order_detail_id,
            "bc_status": 0
        }
        payload.bc_status = res ? 1 : 2;
        let result = await handleEditBcStatus(payload);
    };

    useEffect(() => {
        if (photo_data){
            handleCurrentPage('/send-mail');
            navigate('/send-mail');
        }
    },[photo_data,handleCurrentPage,navigate]);

    return (
      <div className="flex flex-1 flex-col bg-[#3f3f40] text-left text-white w-full overflow-x-auto overflow-y-auto rounded-md">
        {photo_details.length > 0 ? (
          <table className="m-3">
            <thead>
              <tr className="text-nowrap">
                <th
                  scope="col"
                  className="pl-1 pr-2 py-1 text-start text-xs md:text-xl lg:text-lg xl:text-xl text-gray-500 dark:text-neutral-500"
                >
                  Ref#
                </th>
                <th
                  scope="col"
                  className="pl-1 pr-2 py-1 text-start text-xs md:text-xl lg:text-lg xl:text-xl text-gray-500 dark:text-neutral-500"
                >
                  Use Case
                </th>
                <th
                  scope="col"
                  className="pl-1 pr-2 py-1 text-start text-xs md:text-xl lg:text-lg xl:text-xl text-gray-500 dark:text-neutral-500"
                >
                  Activity
                </th>
                <th
                  scope="col"
                  className="pl-1 pr-3 py-1 text-start text-xs md:text-xl lg:text-lg xl:text-xl text-gray-500 dark:text-neutral-500"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-1 py-1 text-center text-xs md:text-xl lg:text-lg xl:text-xl text-gray-500 dark:text-neutral-500"
                >
                  BC Update
                </th>
              </tr>
            </thead>
            <tbody>
              {photo_details.map((photo) =>
                photo.order_activity_dtl.map((activity, index) => (
                  <tr key={`${photo.id}-${index}`} className="text-nowrap">
                    <td className="pl-1 pr-2 py-1 text-xs md:text-xl lg:text-lg  text-white dark:text-neutral-200">
                      {index === 0 ? photo.ref_no : ""}
                    </td>
                    <td className="pl-1 pr-2 py-1 text-xs md:text-xl lg:text-lg  text-white dark:text-neutral-200">
                      {activity.use_case_dtl.name}
                    </td>
                    <td className="pl-1 pr-2 py-1 text-xs md:text-xl lg:text-lg  text-white ark:text-neutral-20d0">
                      <a
                        href={activity.photo_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {activity.activity_dtl.name}
                      </a>
                    </td>
                    <td className="pl-1 pr-3 py-1 text-xs md:text-xl lg:text-lg ">
                      <button
                        type="button"
                        onClick={() =>
                          handlePhotoSend(activity.order_detail_id)
                        }
                        className="inline-flex items-center gap-x-2 text-xs md:text-xl lg:text-lg  font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400"
                      >
                        Send
                      </button>
                    </td>
                    <td className="px-1 py-1 text-center text-xs md:text-xl lg:text-lg  text-white dark:text-neutral-200">
                      {activity.bc_update_status === 1 ? (
                        "Done"
                      ) : activity.bc_update_status === 2 ? (
                        <button
                          className=" text-blue-400 hover:text-blue-600 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400"
                          onClick={() =>
                            handleUpdateBc(
                              photo.ref_no,
                              activity.use_case_dtl.id,
                              activity.photo_link,
                              activity.order_detail_id
                            )
                          }
                        >
                          Retry
                        </button>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : !is_photo_loading ? (
          <div className="m-3 text-center text-md text-gray-500">
            No data found
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    );
};

export default PhotoTable;
