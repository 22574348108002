export const parser_create_activity = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

export const parser_edit_activity = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

export const parser_get_activity_details = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

export const parser_delete_activity = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
export const parser_get_activities_list = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
