import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setPage } from '../../store/home/homeSlice';
import { setPhotoData } from '../../store/photo_list/photoSlice';
import { setImgGallery, setOrderDetails, setPhotoLink, setReferenceNo, setScanType, setSelectedActivity, setSelectedUseCase } from '../../store/common/commonSlice';
import { SHAREPOINT_LINK } from '../../helpers/constants';

const Sidebar = (props) => {
    const {ToggleSidebar,isOpen,modules} = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNavigation = (link) => {
        dispatch(setPhotoData())
        dispatch(setPage(link));
        dispatch(setImgGallery(null));
        dispatch(setReferenceNo(null));
        dispatch(setScanType(''));
        dispatch(setOrderDetails(null));
        dispatch(setSelectedUseCase(null));
        dispatch(setSelectedActivity(null));
        dispatch(setPhotoLink(null));
        navigate(link);
        ToggleSidebar();
    }
    const path_url = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className={`sidebar w-[280px] h-full bg-[#161616] fixed top-0 z-[999] -left-[100%] ${isOpen === true ? 'left-0' : ''}`}>
                    <div className="flex items-center justify-end p-[15px]">
                        <div className="text-white font-bold" onClick={ToggleSidebar}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                        </div>
                    </div>
                    <div className="p-[15px] overflow-x-hidden">
                        <ul className='inline-block w-full mb-0 p-0'>
                            {
                                modules.map((module,idx) => {
                                    return (
                                        <li className='list-none mb-[4px]' key={idx}>
                                            <label
                                            onClick={() => handleNavigation(module.link)} 
                                            className={`inline-block w-full py-[10px] px-[16px] text-white cursor-pointer no-underline rounded-md ${path_url === module.link ? 'bg-gray-500' : ''}`}>
                                                {module.title}
                                            </label>
                                        </li>
                                    )
                                })
                            }
                            <li className='list-none mb-[4px]'>
                                <a href={SHAREPOINT_LINK} target='_blank'
                                rel="noreferrer"
                                className="inline-block w-full py-[10px] px-[16px] text-white cursor-pointer no-underline rounded-md">
                                    SharePoint
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`sidebar-overlay ${isOpen === true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
           </div>
        </>
    )
}

export default Sidebar