import React, { Component } from 'react'
import Gallery from '../../components/camera/Gallery';
import axios from "axios";
import { baseUrl } from '../../helpers/constants';
import { POST_UPLOAD_IMAGE_URL } from '../../store/home/homeConstants';
import { UPDATE_BC_PHOTO_LINK_URL } from '../../store/camera/cameraConstants';

export class GalleryContainer extends Component {

  componentDidMount(){
    const {currentPage} = this.props;
    if (currentPage !== '/camera'){
      window.location.replace('/');
    }
  }

  removeGalleryImg = (idx) => {
    this.props.removeUploadedGalleryImg(idx)
  };

  handleCreatePhotoList = async (payload) => {
    let res = await this.props.createPhotoList(payload);
    return res
  }

  uploadFile = async (file,folderName) => {
    const {order_receiving} = this.props;
    const {selectedActivity,referenceNo} = order_receiving;
    const formData = new FormData();
    formData.append('image', file);
    let params = referenceNo.length === 0 ? 
                `?activity_id=${selectedActivity}&folder_name=${folderName}` : 
                `?activity_id=${selectedActivity}&reference_no=${referenceNo}&folder_name=${folderName}`;

    await axios.post(baseUrl + POST_UPLOAD_IMAGE_URL + params,formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      const {key,folder_path} = response.data;
      const payload = {id : key,newStatus : true}
      this.props.updateGalleryImgStatus(payload);
      this.props.setPhotoLink(folder_path);
    })
    .catch(error => {
      console.error(error); // Handle upload errors
      this.props.setImageLoading(false);
    });
  };

  updateBc = async () => {
    const {order_receiving} = this.props;
    const {selectedUseCase,photoLink,referenceNo} = order_receiving;
    const payload = {
      order_no : referenceNo,
      usecase_id : selectedUseCase,
      photo_link_url : photoLink
    }

    let res = await axios.post(baseUrl + UPDATE_BC_PHOTO_LINK_URL , payload)
    .then(response => {
      return true;
    })
    .catch(error => {
      console.error(error); 
      return false;
    });

    return res;
  }

  handleImageLoading = (newVal) => {
    this.props.setImageLoading(newVal);
  }

  handleResetData = () => {
    this.props.setImgGallery(null);
    this.props.setReferenceNo(null);
    this.props.setScanType('');
    this.props.setOrderDetails(null);
    this.props.setSelectedUseCase(null);
    this.props.setSelectedActivity(null);
    this.props.setPhotoLink(null);
  }

  render() {
    const {order_receiving,is_image_loading} = this.props;
    const {imgGallery} = order_receiving
    console.log("order_receiving",order_receiving);
    return <Gallery 
            imgGallery={imgGallery} 
            removeGalleryImg={this.removeGalleryImg}
            uploadFile = {this.uploadFile}
            handleImageLoading = {this.handleImageLoading}
            is_image_loading = {is_image_loading}
            handleCreatePhotoList = {this.handleCreatePhotoList}
            order_receiving = {order_receiving}
            handleResetData = {this.handleResetData}
            updateBc = {this.updateBc}
            />
  }
}

export default GalleryContainer