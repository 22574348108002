import React, { Component } from 'react'
import ActivityTable from '../../components/activities/ActivityTable';
import ActivitySearch from '../../components/activities/ActivitySearch';
import ActivityForm from '../../components/activities/ActivityForm';

export class ActivityContainer extends Component {
  componentDidMount(){
    const {currentPage} = this.props;
    if (currentPage !== '/activities' && currentPage !== "/create-activity" && currentPage !=="/edit-activity"){
      window.location.replace('/');
    }
    const { qs, page, limit } = this.props;
    this.props.getActivitiesList({ qs, page, limit });
    // this.props.setCommonLoading(true);
  }

  handleCurrentPage = async (newPage) => {
    await this.props.setPage(newPage);
  }

  callUseCaseList = () => {
    this.props.getUseCaseList();
  }

  handleCreateActivity = async (payload) => {
    const res = await this.props.createActivity(payload);
    return res
  }
  
  handleEditActivity = async (payload) => {
    const res = await this.props.editActivity(payload);
    return res
  }

  handleActivityDetails = async (activity_id) => {
    const res = await this.props.getActivityDetails(activity_id);
    return res
  }

  handleDeleteActivity = async (activity_id) => {
    let res = await this.props.deleteActivity(activity_id);
    if (!res.error) {
      this.props.getActivitiesList({ qs : '', page : 1, limit : 10 });
    }
  }
    

  handleSearch = () => {
    const {searchValue} = this.props;
    const search = searchValue.trim();
    this.props.setSearchParams({ qs: search, page: 1 });
    this.props.getActivitiesList({ qs: search, page: 1, limit: this.props.limit });
  };

  handlePrevPage = () => {
      this.props.goToPrevPage();
      this.props.getActivitiesList({
          qs: this.props.qs,
          page: this.props.page - 1,
          limit: this.props.limit
      });
  };

  handleNextPage = () => {
      this.props.goToNextPage();
      this.props.getActivitiesList({
          qs: this.props.qs,
          page: this.props.page + 1,
          limit: this.props.limit
      });
  };
    
  render() {
    const {currentPage,use_case_list, activity_details, activity_list, page, limit, totalPages, totalItems, searchValue, setSearchValue, is_activity_loading} = this.props;
    return (
      <section className='flex flex-col flex-1 h-full mx-2.5 pb-2.5 space-y-2'>
        {
          (currentPage !== '/create-activity' && currentPage !== '/edit-activity') ? 
          (
            <>
              <ActivitySearch 
                handleCurrentPage={this.handleCurrentPage}
                searchValue={searchValue}
                onSearch={this.handleSearch}
                onPrevPage={this.handlePrevPage}
                onNextPage={this.handleNextPage}
                page={page}
                limit={limit}
                totalPages={totalPages}
                totalItems={totalItems}
                setSearchValue={setSearchValue}
                activity_list={activity_list}
              />
              <ActivityTable
                handleCurrentPage={this.handleCurrentPage} 
                handleActivityDetails = {this.handleActivityDetails}
                handleDeleteActivity = {this.handleDeleteActivity}
                activity_list={activity_list} 
                is_activity_loading={is_activity_loading}
              />
            </>
          )
          : <ActivityForm 
            handleCurrentPage={this.handleCurrentPage} 
            callUseCaseList = {this.callUseCaseList}
            use_case_list={use_case_list} 
            handleCreateActivity = {this.handleCreateActivity}
            is_edit = {currentPage === '/create-activity' ? false : true}
            activity_details = {activity_details}
            handleEditActivity = {this.handleEditActivity}
          />

        }
      </section>
    )
  }
}

export default ActivityContainer;