import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api_create_photo_list_url } from "./cameraApi";
import { parser_create_photo_list } from "./cameraParser";

export const createPhotoList = createAsyncThunk("createPhotoList",async (payload) => {
  try {
      const response = await api_create_photo_list_url(payload);
      const data = await parser_create_photo_list(response);
      return {data};
  } catch(error){
      throw new Error(error);
  }
});


const initialState = {
  imgGallery : [],
  err : "",
  camera_option : "user",
  is_camera_loading : false,
  is_start : false
}

export const cameraSlice = createSlice({
  name : "camera",
  initialState,
  reducers : {
      setGallery: (state, action) => {
        state.imgGallery = [action.payload,...state.imgGallery];
      },
      setErr : (state,action) => {
        state.err = action.payload;
      },
      removeGalleryImg : (state,action) => {
        state.imgGallery = state.imgGallery.filter((img,idx)=> idx !== action.payload);
      },
      setCameraOption : (state,action) => {
        state.camera_option = action.payload;
      },
      setCameraStart : (state,action) => {
        state.is_start = action.payload;
      }
  },
  extraReducers : async (builder) => {
    builder
    // create photo list
    .addCase(createPhotoList.pending, (state, action) => {
        state.is_camera_loading = true;
    })
    .addCase(createPhotoList.fulfilled, (state, action) => {
        state.is_camera_loading = false;
    })
    .addCase(createPhotoList.rejected, (state, action) => {
        state.is_camera_loading = false;
    });
},
});

export const {
  setGallery,
  setErr,
  removeGalleryImg,
  setCameraOption,
  setCameraStart
} = cameraSlice.actions;

export default cameraSlice.reducer;