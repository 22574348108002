import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setScanType } from '../../store/common/commonSlice';

const Scan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handlePageRedirect = () => {
    dispatch(setScanType(1));
    navigate('/scan');
  }
  return (
    <div>
        <p className='lg:text-3xl md:text-5xl text-3xl text-gray-400 lg:mb-3 md:mb-6 mb-3'>Click <strong className='text-white'>Scan</strong> to get started.</p>
        <button 
            className="lg:text-lg md:text-3xl sm:text-lg 
            lg:px-3 lg:py-3
            md:px-7 md:py-5
            px-4 py-4 font-semibold 
            bg-red-600 text-white 
            rounded-md shadow-sm lg:mb-1 md:mb-4 mb-1"
            onClick={handlePageRedirect}
        >Scan</button>
    </div>
  )
}

export default Scan;