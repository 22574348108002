import React, { useCallback, useEffect, useRef, useState } from 'react'
// import WebCamera from './WebCamera';
import { Link } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import { useDispatch } from 'react-redux';
import { setCommonLoading } from '../../store/common/commonSlice';

const Camera = (props) => {
  const {handleGalleryImg,imgGallery,handleErr,err} = props;
  const [useFrontCamera,setUseFrontCamera] = useState(false);
  const [streaming,setStreaming] = useState(false);
  const [width,setWidth] = useState(window.innerWidth);
  const [height,setHeight] = useState(0);
  const [is_loading,setIsLoading] = useState(false);

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const dispatch = useDispatch();
  let currentImg = imgGallery.at(0);

  const handleMediaError = useCallback((msg) => {
    const {message} = msg;
    handleErr(message);
    dispatch(setCommonLoading(false));
  },[dispatch,handleErr]);

  const handleVideoStream = () => {
    if (!streaming) {
      let video = webcamRef.current;
      let canvas = canvasRef.current;
      let hgt = video.videoHeight / (video.videoWidth / width);
      setHeight(hgt);
      
      if (isNaN(height)) {
        hgt = width / (4 / 3);
        setHeight(hgt);
      }
      canvas.width = width;
      canvas.height = height;
      video.width = width;
      video.height = height;
      setStreaming(true);
    }
  }

  // switch camera
  const handleSwitchCamera = async () => {
    setUseFrontCamera(!useFrontCamera);
    setStreaming(false);
    await initializeCamera();
  };

  const initializeCamera = useCallback(async () => {
    const constraints = {
      video : {
          width: {
              min: 1280,
              ideal: 1920,
              max: 2560,
          },
          height: {
              min: 720,
              ideal: 1080,
              max: 1440
          },
      }
    }
    constraints.video.facingMode = useFrontCamera ? "user" : "environment";
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (webcamRef.current){
        webcamRef.current.srcObject = stream;
      }
      dispatch(setCommonLoading(false));
    } catch (error) {
      handleMediaError(error);
      dispatch(setCommonLoading(false));
    }
    
  },[handleMediaError,useFrontCamera,dispatch]);

  useEffect(()=>{
    dispatch(setCommonLoading(true));
    initializeCamera();
  },[dispatch,initializeCamera])

  // Capture snapshots using HTML Canvas
  const captureImage = () => {
    let video = webcamRef.current;
    let canvas = canvasRef.current;
    const img_id = uuid();
    setIsLoading(true);
    const canvasContext = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvasContext.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    canvasContext.rotate(Math.PI / 2);
    // if (video.videoWidth > video.videoHeight) {
    //   canvasContext.rotate(90 * Math.PI / 180);
    // }
    // Convert captured data to image (base64)
    const data = canvas.toDataURL('image/jpeg');
    let imgData = {
      id : img_id,
      data : data,
      is_uploaded : false
    }
    handleGalleryImg(imgData);
  }

  useEffect(() => {
    if (is_loading){
      setTimeout(() => {
          setIsLoading(false);
      }, 400);
    }
  },[is_loading]);

  return (
    <div 
      className="w-full h-full relative" 
      style={{
        opacity : is_loading ? 0.5 : 1,
        transition: "opacity 0.1s ease",
      }}>
      {err.length === 0 ? (
        <video className='w-full h-full' ref={webcamRef} onCanPlay={handleVideoStream} autoPlay>Video stream not available.</video>
      ) : (
        <div className="flex justify-center items-center h-full select-none">
          <p className="p-4 text-white">{err}</p>
        </div>
      )}

      <canvas className='hidden' id="canvas" ref={canvasRef}></canvas>
      <div className="absolute bottom-0 items-center w-full md:h-40 lg:h-24 xl:h-40 sm:h-28 h-28 py-5 px-8 bg-[rgba(0,0,0,0.6)] rounded-t-lg flex md:justify-evenly justify-between lg:justify-around xl:justify-around">
        <div className="bg-gray-400 text-white h-16 sm:h-16 md:h-24 lg:h-16 xl:h-16 w-20 sm:w-20 md:w-28 lg:w-20 xl:w-20 rounded-xl flex justify-center items-center cursor-pointer">
          <Link
            to="/gallery"
            className="h-full w-full flex justify-center items-center"
          >
            {currentImg ? (
              <img
                src={currentImg.data}
                className="w-full h-full rounded-xl object-fill"
                alt=""
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-9 md:size-16 sm:size-9 lg:size-9 xl:size-9"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            )}
          </Link>
        </div>
        <div
          className="bg-white p-6 h-16 sm:h-16 md:h-24 lg:h-16 xl:h-16 w-20 sm:w-20 md:w-28 lg:w-20 xl:w-20  rounded-2xl border-red-600 border-2 flex justify-center items-center cursor-pointer"
          {...(err.length === 0 && { onClick: captureImage })}
        >
          <div className="border-2 border-red-600 rounded-full p-1">
            <div className="bg-red-700 w-6 h-6 sm:w-6 sm:h-6 md:w-11 md:h-11 lg:w-6 lg:h-6 xl:w-6 xl:h-6 rounded-full"></div>
          </div>
        </div> 
        <div
          className="bg-gray-400 text-white h-16 sm:h-16 md:h-24 lg:h-16 xl:h-16 w-20 sm:w-20 md:w-28 lg:w-20 xl:w-20 rounded-xl flex justify-center items-center cursor-pointer"
          {...(err.length === 0 && { onClick: handleSwitchCamera })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-9 md:size-16 sm:size-9 lg:size-9 xl:size-9"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Camera;