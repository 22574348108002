import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
    setCommonLoading
} from "../../store/common/commonSlice";
import { getPhotoList, setSearchParams, setSearchValue, goToNextPage, goToPrevPage,getPhotoDetails,editBcStatus } from '../../store/photo_list/photoSlice';
import { setPage } from "../../store/home/homeSlice";
const mapStateToProps = (state) => {
    const {
        photo_details,
        is_photo_loading,
        page,
        totalPages,
        totalItems,
        ref_no,
        limit,
        searchValue,
        photo_data
    } = state.photo_list;
    
    return {
        photo_details,
        is_photo_loading,
        page,
        totalPages,
        totalItems,
        ref_no,
        limit,
        searchValue,
        photo_data
    };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            getPhotoList,
            setSearchParams,
            setSearchValue,
            goToNextPage,
            goToPrevPage,
            getPhotoDetails,
            setPage,
            editBcStatus,
            setCommonLoading
        },
        dispatch
    );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
