import { get } from "lodash-es";

export const parser_get_home = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

export const parser_get_bc_order_details = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return {
        SalesOrderNo : get(response,"No",""),
        CustomerName : get(response,"Sell_to_Customer_Name",""),
        CustomerEmail : get(response,"Sell_to_EMail",""),
        CustomerNo : get(response,"Sell_to_Customer_No",""),
        ShippingDate : get(response,"Shipment_Date",""),
        ShippingFrom : get(response,"Location_Code",""),
        ShippingAgent : get(response,"Shipping_Agent_Code",""),
        ShippingMethod : get(response,"Shipping_Agent_Service_Code",""),
        ExternalDocumentNo : get(response,"External_Document_No",""),
        CreatedBy : get(response,"created_by","")
      };
    } catch (error) {
      throw new Error(error);
    }
  };