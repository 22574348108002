import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
import LayoutView from '../pages/layout/LayoutView.jsx';
import PublicRoutes from './PublicRoutes.jsx';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import axios from 'axios';
import { GET_GROUP_MEMBEROF_URL } from '../store/home/homeConstants.js';
import { baseUrl, GROUP_NAME } from '../helpers/constants.js';
import { useDispatch, useSelector } from 'react-redux';
import { setCommonLoading, setIsAdmin } from '../store/common/commonSlice.js';

const Index = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance,accounts,inProgress } = useMsal();
  const dispatch = useDispatch();
  const {is_admin} = useSelector(state => state.common);
  
  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None){
      dispatch(setCommonLoading(true));
      const accessTokenRequest = {
        account: accounts[0],
      };
      
      instance
      .acquireTokenSilent(accessTokenRequest)
      .then(async (accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;
        const params = {
          username : accessTokenRequest.account.username,
          token : accessToken,
          group_name : GROUP_NAME
        };
        await callGroupMemberOfAPI(params);
        dispatch(setCommonLoading(false));
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          dispatch(setCommonLoading(true));
          await instance
            .acquireTokenPopup(accessTokenRequest)
            .then(async function (accessTokenResponse) {
              let accessToken = accessTokenResponse.accessToken;
              const params = {
                username : accessTokenRequest.account.username,
                token : accessToken,
                group_name : GROUP_NAME
              };
              await callGroupMemberOfAPI(params);
              dispatch(setCommonLoading(false));
            })
            .catch(async function (error) {
              // Acquire token interactive failure
              console.log(error);
              dispatch(setCommonLoading(false));
            });
        }
        console.log(error);
        dispatch(setCommonLoading(false));
      });

    }

    const callGroupMemberOfAPI = async (params) => {
      await axios.get(baseUrl + GET_GROUP_MEMBEROF_URL,{params}).then((response) => {
        const {value} = response.data;
        let is_admin = value.length === 0 ? false : true;
        dispatch(setIsAdmin(is_admin));
      }).catch((error) => {
        console.log(error);
        instance.logoutRedirect();
      });
    }
  },[isAuthenticated,instance,accounts,inProgress,dispatch,is_admin]);

  return (
    <Router>
      <Routes>
        {
          isAuthenticated ? (
            <Route path="/*" element={<LayoutView />} />
          ) : (
            <Route path="/*" element={<PublicRoutes />} />
          )
        }
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  )
}

export default Index;
