import React from 'react'
import { useNavigate } from 'react-router-dom';

const ActivityTable = (props) => {
  const {handleCurrentPage,handleActivityDetails,handleDeleteActivity,activity_list} = props;
  const navigate = useNavigate();

  const handleEdit = async (activity_id) => {
    let res = await handleActivityDetails(activity_id);
    if (!res.error){
      await handleCurrentPage('/edit-activity');
      navigate('/edit-activity');
    }
  }

  const handleDelete = async (activity_id) => {
    const action = window.confirm("Are you sure you want to delete this activity");
    if (action){
        await handleDeleteActivity(activity_id);
    }
  }
  return (
    <div className="flex flex-1 flex-col text-left text-white w-full overflow-x-auto rounded-md">
        <table>
            <thead className='bg-[#3f3f40] rounded-md'>
                <tr className='text-nowrap'>
                    <th scope="col" className="pl-1 pr-2 py-2 text-start text-xs md:text-xl lg:text-lg text-gray-500 dark:text-neutral-500">Use Case</th>
                    <th scope="col" className="pl-1 pr-2 py-2 text-start text-xs md:text-xl lg:text-lg text-gray-500 dark:text-neutral-500">Activity</th>
                    <th scope="col" className="pl-1 pr-2 py-2 text-start md:text-center text-xs md:text-xl lg:text-lg text-gray-500 dark:text-neutral-500">Active</th>
                    <th scope="col" className="pl-1 pr-2 py-2 text-start text-xs md:text-xl lg:text-lg text-gray-500 dark:text-neutral-500">Actions</th>
                </tr>
            </thead>
            <tbody>
                {activity_list.map((activity) => (
                    <tr key={activity.id} className='text-nowrap'>
                        <td className="pl-1 pr-2 py-1 text-xs md:text-xl lg:text-lg text-white dark:text-neutral-200">
                            {activity.usecase_dtl.name}
                        </td>
                        <td className="pl-1 pr-2 py-1 text-xs md:text-xl lg:text-lg text-white dark:text-neutral-200">
                            {activity.name}
                        </td>
                        <td className="flex justify-center items-center pl-1 pr-2 py-3 text-xs md:text-xl lg:text-lg text-center text-white dark:text-neutral-20d0">
                            {/* <a href={activity.photo_link} target='_blank' rel="noreferrer">{activity.activity_dtl.name}</a> */}
                            <input type='checkbox' className='checked:accent-gray-500 md:scale-150 lg:scale-150' defaultChecked={activity.is_active} disabled/>
                            
                        </td>
                        <td className="pl-1 pr-2 py-1 text-start text-xs md:text-xl lg:text-lg space-x-2">
                            <button
                            onClick={() => handleEdit(activity.id)}
                            type="button" 
                            className="inline-flex items-center gap-x-2 text-xs md:text-xl lg:text-lg font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400">
                                Edit
                            </button>
                            <button
                                onClick={() => handleDelete(activity.id)}
                                type="button" 
                                className="inline-flex items-center gap-x-2 text-xs md:text-xl lg:text-lg font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400">
                                Delete
                            </button>
                        </td>
                    </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
  )
}

export default ActivityTable;