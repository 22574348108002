import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
    setSelectedActivity,
    setSelectedUseCase,
    setOrderDetails,
    setBcUpdate
  } from "../../store/common/commonSlice";
import { getUseCaseList,setPage } from "../../store/home/homeSlice";

const mapStateToProps = (state) => {
  const {
    currentPage,
    use_case_list,
  } = state.home;

  const {
    orderReceiving,
  } = state.common;

  return {
    currentPage : currentPage,
    use_case_list : use_case_list,
    order_receiving : orderReceiving
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            getUseCaseList,
            setSelectedActivity,
            setSelectedUseCase,
            setPage,
            setOrderDetails,
            setBcUpdate
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;