import React from 'react';
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { GET_BC_PURCHASE_ORDER_DETAILS, ORPHAN_RECEIVING, PO_RECEIVING } from '../../store/home/homeConstants';
import axios from 'axios';
import { baseUrl } from '../../helpers/constants';
import { useDispatch } from 'react-redux';
import { setCommonLoading } from '../../store/common/commonSlice';
import { parser_get_bc_order_details } from '../../store/home/homeParser';

const Receiving = (props) => {
  const {
    use_cases_details,
    getOrderDetails,
    handleOrderReset,
    handleCurrentPage,
    handleReceiving,
    handleOrderDetails
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const validationSchema = yup.object().shape({
    activity_id : yup.number().required('please select activity.'),
    po_number: yup.mixed().when('activity_id',{
      is : (value) => value !== ORPHAN_RECEIVING,
      then :  () => yup.string().required('PO # is required').trim(),
      otherwise : () => yup.string().trim(),
    })
  });

  const callPurchaseOrderDetailsAPI = async (params) => {
    const response = await axios.get(baseUrl + GET_BC_PURCHASE_ORDER_DETAILS,{params}).then(async (response)=>{
      const result = response.data;
      const paresedata = await parser_get_bc_order_details(result);
      handleOrderDetails(paresedata);
      return response;
    }).catch((err) => {
      dispatch(setCommonLoading(false));
      alert(err?.response?.data?.detail || err.detail || "Something went wrong!");
      return err;
    });
    return response;
  }

  const onSubmit = async (values) => {
    const {activity_id,po_number} = values;
    let date = new Date()
    let currentDate = date.toLocaleDateString().replaceAll('/','_');
    let time = `${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;
    const dateFormat = `${currentDate}_${time}`;
    po_number.length > 0 ? handleReceiving(activity_id,po_number) : handleReceiving(activity_id,`OR-${dateFormat}`);
    if (activity_id !== ORPHAN_RECEIVING.toString() && activity_id !== PO_RECEIVING.toString()) {
      let res = await getOrderDetails(values);
      if (!res.error){
        const {data} = res.payload;
        handleOrderDetails(data);
        handleCurrentPage('/camera');
        navigate('/camera');
      }
      formik.resetForm();
    }
    else if (activity_id === PO_RECEIVING.toString()){
      let params = {
        purchase_no : values.po_number
      }
      dispatch(setCommonLoading(true));
      let res = await callPurchaseOrderDetailsAPI(params);
      if (res.status === 200){
        dispatch(setCommonLoading(false));
        // handleOrderReset();
        handleCurrentPage('/camera');
        navigate('/camera');
      }
      formik.resetForm();
    }
    else{
      formik.resetForm();
      handleOrderReset();
      handleCurrentPage('/camera');
      navigate('/camera');
    }
  };

  const formik = useFormik({
    enableReinitialize : true,
    initialValues : {
      activity_id : '',
      po_number : ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  return (
    <div>
      <p className="lg:text-xl md:text-3xl text-xl lg:mt-3 md:mt-6 mt-3">
        For <strong className="text-white">Receiving</strong>
      </p>
      <p className="lg:mb-6 md:mb-8 mb-8 lg:text-xl md:text-3xl text-xl">
        Select the activity to get started.
      </p>
      <form className="space-y-5" onSubmit={formik.handleSubmit}>
        <div>
          <select
            className="lg:py-2 text-gray-950 md:py-5 py-2 px-4 pe-9 block w-full bg-gray-300 border-transparent rounded-sm text-sm md:text-xl lg:text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            name="activity"
            value={formik.values.activity_id}
            onChange={formik.handleChange("activity_id")}
            onBlur={formik.handleBlur("activity_id")}
          >
            <option value="" disabled>
              Select Activity
            </option>
            {use_cases_details.map((activity, idx) => {
              return (
                <option className="text-gray-950" key={idx} value={activity.id}>
                  {activity.name}
                </option>
              );
            })}
          </select>
          <p className="text-red-600 text-start lg:text-sm md:text-xl xl:text-sm text-sm">
            {formik.touched.activity_id && formik.errors.activity_id}
          </p>
        </div>
        {(formik.values.activity_id === "" ||
          formik.values.activity_id !== ORPHAN_RECEIVING.toString()) && (
          <div>
            <input
              type="text"
              className="bg-gray-300 text-gray-950 lg:py-2 md:py-4 py-2 px-4 block w-full border-gray-200 rounded-sm lg:text-sm md:text-2xl text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              placeholder="Enter #"
              value={formik.values.po_number}
              onChange={formik.handleChange("po_number")}
              onBlur={formik.handleBlur("po_number")}
            ></input>
            <p className="text-red-600 text-start lg:text-xs md:text-xl xl:text-sm text-xs">
              {formik.touched.po_number && formik.errors.po_number}
            </p>
          </div>
        )}
        <button
          type="submit"
          className="lg:text-lg md:text-3xl sm:text-lg lg:px-3 lg:py-3
            md:px-7 md:py-5 px-6 py-4 font-semibold bg-red-600 text-white rounded-md shadow-sm"
        >
          OK
        </button>
      </form>
    </div>
  );
}

export default Receiving