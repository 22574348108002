export const parser_get_photo_list = (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

export const parser_get_photo_details = (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

export const parser_send_mail = (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  export const parser_edit_bc_status = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };