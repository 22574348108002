import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
    setScanType,
    setReferenceNo,
    setSelectedActivity,
    setSelectedUseCase,
    setOrderDetails
  } from "../../store/common/commonSlice";
import { getHomeList, getOrderReturnDetails,resetOrderDetails,setPage } from "../../store/home/homeSlice";

const mapStateToProps = (state) => {
  const {
    use_cases_details,
    is_home_loading
  } = state.home;

  const {
    orderReceiving
  } = state.common;

  return {
    use_cases_details : use_cases_details,
    is_home_loading : is_home_loading,
    orderReceiving : orderReceiving
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            getHomeList,
            getOrderReturnDetails,
            resetOrderDetails,
            setPage,
            setScanType,
            setReferenceNo,
            setSelectedActivity,
            setSelectedUseCase,
            setOrderDetails
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;