import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
    setImgGallery,
    setCommonLoading
  } from "../../store/common/commonSlice";
import { setGallery,setErr,setCameraOption,setCameraStart } from "../../store/camera/cameraSlice";

const mapStateToProps = (state) => {
  const {
    currentPage
  } = state.home;

  const {
    imgGallery,
    err,
    camera_option,
    is_start
  } = state.camera;

  const {
    orderReceiving
  } = state.common;

  return {
    currentPage : currentPage,
    imgGallery : imgGallery,
    err : err,
    camera_option : camera_option,
    order_receiving : orderReceiving,
    is_start : is_start
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            setGallery,
            setErr,
            setCameraOption,
            setImgGallery,
            setCommonLoading,
            setCameraStart
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;