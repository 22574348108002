import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api_create_activity_url, api_delete_activity_url, api_edit_activity_url, api_get_activity_details_url,api_get_activities_list_url } from "./activityApi";
import { parser_create_activity, parser_delete_activity, parser_edit_activity, parser_get_activity_details,parser_get_activities_list } from "./activityParser";

export const createActivity = createAsyncThunk("createActivity",async (payload) => {
  try {
    const response = await api_create_activity_url(payload);
    const data = await parser_create_activity(response);
    return {data};
  } catch(error){
    throw new Error(error);
  }
});

export const editActivity = createAsyncThunk("editActivity",async (payload) => {
  try {
    const {id} = payload;
    const activity_id = id;
    delete payload.id;
    const response = await api_edit_activity_url(activity_id,payload);
    const data = await parser_edit_activity(response);
    return {data};
  } catch(error){
    throw new Error(error);
  }
});

export const getActivityDetails = createAsyncThunk("getActivityDetails",async (payload) => {
  try {
    const response = await api_get_activity_details_url(payload);
    const data = await parser_get_activity_details(response);
    return {data};
  } catch(error){
    throw new Error(error);
  }
});

export const deleteActivity = createAsyncThunk("deleteActivity",async (payload) => {
  try {
    const response = await api_delete_activity_url(payload);
    const data = await parser_delete_activity(response);
    return {data};
  } catch(error){
    throw new Error(error);
  }
});

export const getActivitiesList = createAsyncThunk("getActivitiesList", async (payload) => {
  try {
      const { qs, page, limit } = payload;
      const params = { qs, page, limit };
      const response = await api_get_activities_list_url(params);
      const data = parser_get_activities_list(response);
      return { data };
  } catch (error) {
      throw new Error(error);
  }
});

const initialState = {
  is_activity_loading : false,
  activity_details : null,
  activity_list : [],
  qs: '',
  searchValue: '',
  page: 1,
  limit: 10,
  totalItems: 0,
  totalPages: 0,
}

export const activitySlice = createSlice({
  name : "activity",
  initialState,
  reducers : {
      setSearchParams(state, action) {
        state.qs = action.payload.qs;
        state.page = action.payload.page || state.page;
        state.limit = action.payload.limit || state.limit;
      },
      setSearchValue(state, action) {
          state.searchValue = action.payload;
      },
      goToNextPage(state) {
          if (state.page < state.totalPages) {
              state.page += 1;
          }
      },
      goToPrevPage(state) {
          if (state.page > 1) {
              state.page -= 1;
          }
      }
  },
  extraReducers : async (builder) => {
    builder
    // get activity
    .addCase(getActivitiesList.pending, (state) => {
      state.is_activity_loading = true;
    })
    .addCase(getActivitiesList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.activity_list = data.activities;
        state.totalItems = data.total;
        state.totalPages = Math.ceil(data.total / state.limit);
        state.is_activity_loading = false;
    })
    .addCase(getActivitiesList.rejected, (state) => {
        state.is_activity_loading = false;
    })
    // create activity
    .addCase(createActivity.pending, (state, action) => {
      state.is_activity_loading = true;
    })
    .addCase(createActivity.fulfilled, (state, action) => {
      state.is_activity_loading = false;
    })
    .addCase(createActivity.rejected, (state, action) => {
      state.is_activity_loading = false;
    })

    // edit activity
    .addCase(editActivity.pending, (state, action) => {
      state.is_activity_loading = true;
    })
    .addCase(editActivity.fulfilled, (state, action) => {
      const {data} = action.payload;
      state.activity_list = state.activity_list.map((item) => item.id === data.activity.id ? ({...item,'is_active' : data.activity.is_active}) : item);
      state.is_activity_loading = false;
    })
    .addCase(editActivity.rejected, (state, action) => {
      state.is_activity_loading = false;
    })
    
    // get activity details
    .addCase(getActivityDetails.pending, (state, action) => {
      state.is_activity_loading = true;
    })
    .addCase(getActivityDetails.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.activity_details = data;
      state.is_activity_loading = false;
    })
    .addCase(getActivityDetails.rejected, (state, action) => {
      state.is_activity_loading = false;
    })

    // get activity details
    .addCase(deleteActivity.pending, (state, action) => {
      state.is_activity_loading = true;
    })
    .addCase(deleteActivity.fulfilled, (state, action) => {
      const {arg} = action.meta;
      state.activity_list = state.activity_list.filter((item) => item.id !== arg);
      state.is_activity_loading = false;
    })
    .addCase(deleteActivity.rejected, (state, action) => {
      state.is_activity_loading = false;
    });
},
});

export const { setSearchParams, setSearchValue, goToNextPage, goToPrevPage,setPhotoData } = activitySlice.actions;
export default activitySlice.reducer;