import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    username : '',
};

export const loginSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
      setUsername: (state, action) => {
        state.username = action.payload;
      },
    },
});

// Action creators are generated for each case reducer function
export const {
  setUsername,
} = loginSlice.actions;

export default loginSlice.reducer;