import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_get_photo_details_url, api_get_photo_list_url, api_send_mail_url, api_update_bc_status } from "./photoApi";
import { parser_get_photo_details, parser_get_photo_list, parser_send_mail, parser_edit_bc_status } from "./photoParser";

export const getPhotoList = createAsyncThunk("getPhotoList", async (payload) => {
    try {
        const { ref_no, page, limit } = payload;
        const params = { ref_no, page, limit };
        const response = await api_get_photo_list_url(params);
        const data = parser_get_photo_list(response);
        return { data };
    } catch (error) {
        throw new Error(error);
    }
});

export const getPhotoDetails = createAsyncThunk("getPhotoDetails", async (payload) => {
    try {
        const response = await api_get_photo_details_url(payload);
        const data = parser_get_photo_details(response);
        return { data };
    } catch (error) {
        throw new Error(error);
    }
});

export const sendOrderMail = createAsyncThunk("sendOrderMail", async (payload) => {
    try {
        let params = {
            order_detail_id : payload.order_detail_id
        }
        delete payload.order_detail_id
        const response = await api_send_mail_url(params,payload);
        const data = parser_send_mail(response);
        return { data };
    } catch (error) {
        throw new Error(error);
    }
});

export const editBcStatus = createAsyncThunk("editBcStatus",async (payload) => {
    try {
      const response = await api_update_bc_status(payload);
      const data = await parser_edit_bc_status(response);
      return {data};
    } catch(error){
      throw new Error(error);
    }
});

const initialState = {
    photo_details: [],
    photo_data : null,
    is_photo_loading: false,
    ref_no: '',
    searchValue: '',
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
};

export const photoSlice = createSlice({
    name: "photo_list",
    initialState,
    reducers: {
        setSearchParams(state, action) {
            state.ref_no = action.payload.ref_no;
            state.page = action.payload.page || state.page;
            state.limit = action.payload.limit || state.limit;
        },
        setSearchValue(state, action) {
            state.searchValue = action.payload;
        },
        goToNextPage(state) {
            if (state.page < state.totalPages) {
                state.page += 1;
            }
        },
        goToPrevPage(state) {
            if (state.page > 1) {
                state.page -= 1;
            }
        },
        setPhotoData(state,action){
            state.photo_data = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPhotoList.pending, (state) => {
                state.is_photo_loading = true;
            })
            .addCase(getPhotoList.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.photo_details = data.photos;
                state.totalItems = data.total;
                state.totalPages = Math.ceil(data.total / state.limit);
                state.is_photo_loading = false;
            })
            .addCase(getPhotoList.rejected, (state) => {
                state.is_photo_loading = false;
            })
            // photo details
            .addCase(getPhotoDetails.pending, (state) => {
                state.is_photo_loading = true;
            })
            .addCase(getPhotoDetails.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.photo_data = data;
                state.is_photo_loading = false;
            })
            .addCase(getPhotoDetails.rejected, (state) => {
                state.is_photo_loading = false;
            })
            // send mail
            .addCase(sendOrderMail.pending, (state) => {
                state.is_photo_loading = true;
            })
            .addCase(sendOrderMail.fulfilled, (state, action) => {
                state.is_photo_loading = false;
            })
            .addCase(sendOrderMail.rejected, (state) => {
                state.is_photo_loading = false;
            })

            // edit bc_status
            .addCase(editBcStatus.pending, (state, action) => {
                state.is_photo_loading = true;
            })
            .addCase(editBcStatus.fulfilled, (state, action) => {
                state.is_photo_loading = false;
            })
            .addCase(editBcStatus.rejected, (state, action) => {
                state.is_photo_loading = false;
            });
    },
});

export const { setSearchParams, setSearchValue, goToNextPage, goToPrevPage,setPhotoData } = photoSlice.actions;
export default photoSlice.reducer;
