import React, { Component } from 'react';
import Search from '../../components/photo_list/Search';
import PhotoTable from '../../components/photo_list/PhotoTable';
import { baseUrl } from '../../helpers/constants';
import axios from "axios";
import { UPDATE_BC_PHOTO_LINK_URL } from '../../store/camera/cameraConstants';

export default class PhotoListContainer extends Component {
    componentDidMount() {
        const { ref_no, page, limit } = this.props;
        this.props.getPhotoList({ ref_no, page, limit });
    }

    handleSearch = () => {
        const searchRefNo = this.props.searchValue.trim();
        this.props.setSearchParams({ ref_no: searchRefNo, page: 1 });
        this.props.getPhotoList({ ref_no: searchRefNo, page: 1, limit: this.props.limit });
    };

    handlePrevPage = () => {
        this.props.goToPrevPage();
        this.props.getPhotoList({
            ref_no: this.props.ref_no,
            page: this.props.page - 1,
            limit: this.props.limit
        });
    };

    handleNextPage = () => {
        this.props.goToNextPage();
        this.props.getPhotoList({
            ref_no: this.props.ref_no,
            page: this.props.page + 1,
            limit: this.props.limit
        });
    };

    handlePhotoDetails = (orderDetailID) => {
        this.props.getPhotoDetails(orderDetailID);
    }

    handleCurrentPage = (newPage) => {
        this.props.setPage(newPage);
    }

    handleEditBcStatus = async (payload) => {
        const res = await this.props.editBcStatus(payload);
        if (!res.error){
            this.props.getPhotoList({ ref_no : '', page : 1, limit : 10 });
        }
        return res
    }

    updateBc = async (ref_no, usecase_id, photo_link) => {
        this.props.setCommonLoading(true);
        const payload = {
            order_no : ref_no,
            usecase_id : usecase_id,
            photo_link_url : photo_link
        }

        let res = await axios.post(baseUrl + UPDATE_BC_PHOTO_LINK_URL , payload)
        .then(response => {
            this.props.setCommonLoading(false);
            return true;
        })
        .catch(error => {
            console.error(error);
            this.props.setCommonLoading(false); 
            return false;
        });

        return res;
    }

    render() {
        const { photo_details, is_photo_loading, page, limit, totalPages, totalItems, searchValue, setSearchValue,photo_data} = this.props;

        return (
            <section className='flex flex-col flex-1 lg:px-4 lg:h-full md:px-10 md:h-[45rem] px-4 pb-2.5'>
                <Search
                    searchValue={searchValue}
                    onSearch={this.handleSearch}
                    onPrevPage={this.handlePrevPage}
                    onNextPage={this.handleNextPage}
                    page={page}
                    limit={limit}
                    totalPages={totalPages}
                    totalItems={totalItems}
                    setSearchValue={setSearchValue}
                    photo_details = {photo_details}
                />
                <PhotoTable 
                    photo_details={photo_details} 
                    is_photo_loading={is_photo_loading}
                    handlePhotoDetails = {this.handlePhotoDetails}
                    photo_data = {photo_data}
                    handleCurrentPage = {this.handleCurrentPage}
                    updateBc = {this.updateBc}
                    handleEditBcStatus = {this.handleEditBcStatus}
                />
            </section>
        );
    }
}
