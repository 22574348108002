import { useIsAuthenticated, useMsal} from '@azure/msal-react';
import React from 'react';

const Login = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleSignIn = () => {
    if (!isAuthenticated && accounts.length === 0){
      instance.loginRedirect({
        scopes : ['user.read']
      })
    }
  }

  return (
    <div className='w-full h-full flex justify-center items-center'>
      <button className="px-4 py-2 font-semibold text-sm bg-cyan-500 text-white rounded-full shadow-sm" onClick={handleSignIn}>Sign In</button>
    </div>
  )
}

export default Login