import { configureStore } from "@reduxjs/toolkit";
import axiosInterceptor from "../helpers/axios.js";
import commonSlice from "./common/commonSlice.js";
import loginSlice  from "./auth/authSlice.js";
import homeSlice from "./home/homeSlice.js";
import cameraSlice from "./camera/cameraSlice.js";
import photoSlice from "./photo_list/photoSlice.js";
import activitySlice from "./activity/activitySlice.js";

const store = configureStore({
  reducer: {
    common : commonSlice,
    auth : loginSlice,
    home : homeSlice,
    camera : cameraSlice,
    photo_list : photoSlice,
    activity : activitySlice
  },
});

axiosInterceptor(store.dispatch);

export { store };
