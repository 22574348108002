import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
    setOrderDetails as setScanOrderDetails,
    setReferenceNo,
    setCommonLoading
  } from "../../store/common/commonSlice";
import { setCamera,getUseCaseList,setPage,setOrderDetails } from "../../store/home/homeSlice";

const mapStateToProps = (state) => {
  const {
    order_details,
    camera,
    use_case_list
  } = state.home;

  const {
    orderReceiving,
  } = state.common;

  return {
    order_details : order_details,
    camera : camera,
    use_case_list : use_case_list,
    order_receiving : orderReceiving
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            getUseCaseList,
            setCamera,
            setPage,
            setOrderDetails,
            setScanOrderDetails,
            setReferenceNo,
            setCommonLoading
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;