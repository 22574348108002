import React, { Component } from 'react'
import SendMail from '../../components/photo_list/SendMail'

export class SendMailContainer extends Component {

  componentDidMount(){
    const {currentPage} = this.props;
    if (currentPage !== '/send-mail'){
      window.location.replace('/');
    }
  }
  
  handleSendMail = async (payload) => {
    await this.props.sendOrderMail(payload);
  }

  handleCurrentPage = (newPage) => {
    this.props.setPhotoData();
    this.props.setPage(newPage);
  }
  
  render() {
    const {photo_data} = this.props;
    return (
      <SendMail
        photo_data = {photo_data}
        handleSendMail = {this.handleSendMail}
        handleCurrentPage = {this.handleCurrentPage}
      />
    )
  }
}

export default SendMailContainer