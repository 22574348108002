import React from 'react';
import * as yup from "yup";
import { useFormik } from "formik";
import { GET_BC_ORDER_DETAILS } from '../../store/home/homeConstants';
import { baseUrl } from '../../helpers/constants';
import { parser_get_bc_order_details } from '../../store/home/homeParser';
import { setCommonLoading, setReferenceNo, setScanType } from '../../store/common/commonSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setPage } from '../../store/home/homeSlice';

const SalesOrder = (props) => {
  const {handleOrderDetails} = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
      order_no : yup.number().required('SO# is required.'),
  });

  const callOrderDetailsAPI = async (params) => {
    const response = await axios.get(baseUrl + GET_BC_ORDER_DETAILS,{params}).then(async (response)=>{
      const result = response.data;
      const paresedata = await parser_get_bc_order_details(result);
      handleOrderDetails(paresedata);
      return response;
    }).catch((err) => {
      dispatch(setCommonLoading(false));
      alert(err?.response?.data?.detail || err.detail || "Something went wrong!");
      return err;
    });
    return response;
  }

  const onSubmit = async (values) => {
    dispatch(setCommonLoading(true));
    let res = await callOrderDetailsAPI(values);
    if (res.status === 200) {
      formik.resetForm();
      dispatch(setScanType(1));
      dispatch(setReferenceNo(values.order_no));
      dispatch(setPage('/order-details'));
      dispatch(setCommonLoading(false));
      navigate('/order-details');
    }
  }

  const formik = useFormik({
    enableReinitialize : true,
    initialValues : {
      order_no : ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className='lg:mb-2 md:mb-4 mb-2'>
      <div className='flex space-x-2'>
        <div className='flex-1'>
          <input 
            type="text" 
            className="bg-gray-300 text-gray-950 lg:py-1.5 md:py-4 py-1.5 px-4 block w-full h-full border-gray-200 rounded-sm lg:text-sm md:text-2xl text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            placeholder="Enter SO #"
            value={formik.values.order_no}
            onChange={formik.handleChange("order_no")}
            onBlur={formik.handleBlur("order_no")}
          ></input>
        </div>
        <button 
          type="submit" 
          className="lg:text-base md:text-2xl text-base py-1.5 px-4 font-semibold bg-red-600 text-white rounded-sm shadow-sm"
        >
        OK
        </button>
      </div>
      <p 
        className='text-red-600 text-start lg:text-sm xl:text-sm md:text-xl text-sm'
      >
        {formik.touched.order_no && formik.errors.order_no}
      </p>
    </form>
  )
}

export default SalesOrder