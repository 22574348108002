import React from 'react';

const Search = (props) => {
    const {searchValue, 
        setSearchValue, 
        onSearch, 
        onPrevPage, 
        onNextPage, 
        page,
        limit,
        totalItems, 
        totalPages,
        photo_details
    } = props;
    const handleSearch = () => {
        onSearch();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const startItem = photo_details.length > 0 ? (page - 1) * limit + 1 : 0;
    const endItem = Math.min(page * limit, totalItems);

    return (
        <div>
            <div className='bg-[#3f3f40] text-center text-sm md:text-2xl lg:text-xl text-white w-full rounded-md font-medium'>
                <h2 className='py-1'>Photo List</h2>
            </div>

            <div className='bg-[#3f3f40] rounded-md'>
                <div className='flex px-1 relative text-xs md:text-xl lg:text-lg text-white my-2 gap-2 items-baseline'>
                    <div className='py-1.5 md:py-2 lg:py-1.5 w-[50%]'>
                        Total {startItem}-{endItem} of {totalItems}
                    </div>
                    <div className='relative w-full h-6'>
                        <input
                            className='bg-[#5d5d5e] text-left px-1 py-0.5 w-full rounded-md font-medium'
                            type="text"
                            placeholder="Search by Ref#"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <span className='absolute top-0 right-0 cursor-pointer' onClick={handleSearch}>
                            <svg className='items-end size-5 md:size-8 lg:size-8' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 3 33 19" strokeWidth="1.5" stroke="white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <div className='flex text-white text-xs md:text-xl lg:text-lg pb-1 gap-3 justify-end mx-1'>
                <div
                    className={`cursor-pointer ${page === 1 ? 'text-gray-400' : 'text-white'}`}
                    onClick={page > 1 ? onPrevPage : null}
                >
                    Prev
                </div>
                <div
                    className={`cursor-pointer ${(page === totalPages || photo_details.length  === 0) ? 'text-gray-400' : 'text-white'}`}
                    onClick={page < totalPages ? onNextPage : null}
                >
                    Next
                </div>
            </div>
        </div>
    );
};

export default Search;
