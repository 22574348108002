import React, { Component } from 'react'
import Scan from '../../components/scan/Scan';

export default class ScanContainer extends Component {
  
  componentDidUpdate(){
    const {order_details,navigate} = this.props;
    if (order_details){
      this.props.setPage('/order-details');
      this.props.setCommonLoading(false);
      navigate('/order-details');
    }
  }
 
   callUseCaseList = () => {
    this.props.getUseCaseList()
   }

   handleOrderDetails = (order_data) => {
    this.props.setOrderDetails(order_data);
    this.props.setScanOrderDetails(order_data);
   }

  render() {
    const {order_receiving} = this.props;
    console.log("order_receiving",order_receiving)
    return (
      <>
        {
          <Scan 
            handleOrderDetails={this.handleOrderDetails}
          /> 
        }
      </>
    ) 
  }
}