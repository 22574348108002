import React from 'react'
import OrderDetailsContainer from '../../containers/order_details';
import { useNavigate } from 'react-router-dom';

const OrderDetailsPage = () => {
  const navigate = useNavigate();

  return <OrderDetailsContainer navigate={navigate} />
}

export default OrderDetailsPage;