import React from 'react'
import { useNavigate } from 'react-router-dom';

const ActivitySearch = (props) => {
  const {handleCurrentPage,
    searchValue, 
    setSearchValue, 
    onSearch, 
    onPrevPage, 
    onNextPage, 
    page, 
    totalPages,
    totalItems,
    limit,
    activity_list
  } = props;
  const navigate = useNavigate();

  const handleSearch = () => {
    onSearch();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCreate = async () => {
    await handleCurrentPage('/create-activity');
    navigate('/create-activity');
  };

  const startItem = activity_list.length > 0 ? (page - 1) * limit + 1 : 0;
  const endItem = Math.min(page * limit, totalItems);

  return (
    <div className='space-y-2'>
      <div className="bg-[#3f3f40] text-center text-sm md:text-2xl lg:text-xl text-white w-full rounded-md font-medium flex justify-center items-center">
        <span>Use Case - Activity List</span>
        <div
          className="cursor-pointer text-white  text-sm md:text-2xl lg:text-xl absolute right-4 select-none"
          onClick={handleCreate}
        >
          Create
        </div>
      </div>

      <div className="relative w-full h-8 md:h-10">
        <input
          className="bg-[#3f3f40] text-left text-sm md:text-xl lg:text-lg px-2 py-1.5 w-full rounded-md font-medium text-white"
          type="text"
          placeholder="Search by Use Case Name or Activity Name"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <span className="absolute top-2/4 -translate-y-1/2 right-2 cursor-pointer">
          <svg
            className="items-end size-5 md:size-8 lg:size-5'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 3 33 19"
            strokeWidth="1.5"
            stroke="white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        </span>
      </div>
      <div className="py-1 px-2 bg-[#3f3f40] rounded-md flex w-full justify-between items-center">
        <div className="w-[50%] text-white text-xs md:text-xl lg:text-lg">
          Total {startItem}-{endItem} of {totalItems}
        </div>
        <div className="flex text-white text-xs md:text-xl lg:text-lg pb-1 gap-3 justify-end mx-1">
          <div
            className={`cursor-pointer ${
              page === 1 ? "text-gray-400" : "text-white"
            }`}
            onClick={page > 1 ? onPrevPage : null}
          >
            Prev
          </div>
          <div
            className={`cursor-pointer ${
              page === totalPages || activity_list.length === 0
                ? "text-gray-400"
                : "text-white"
            }`}
            onClick={page < totalPages ? onNextPage : null}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitySearch;
