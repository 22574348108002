import { axios } from "../../helpers/axios";
import { CREATE_ACTIVITY_URL, GET_ACTIVITIES_URL } from "./activityConstants";

export const api_create_activity_url = async (data) => {
    return axios.post(CREATE_ACTIVITY_URL,data);
};

export const api_edit_activity_url = async (activity_id,data) => {
    return axios.put(CREATE_ACTIVITY_URL + '/' + activity_id,data);
};

export const api_get_activity_details_url = async (activity_id) => {
    return axios.get(CREATE_ACTIVITY_URL + '/' + activity_id);
};

export const api_delete_activity_url = async (activity_id) => {
    return axios.delete(CREATE_ACTIVITY_URL + '/' + activity_id);
}

export const api_get_activities_list_url = async (params) => {
    return axios.get(GET_ACTIVITIES_URL,{params});
};