import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
  } from "../../store/common/commonSlice";
import { sendOrderMail,setPhotoData } from "../../store/photo_list/photoSlice";
import { setPage } from "../../store/home/homeSlice";

const mapStateToProps = (state) => {
  const {
    currentPage
  } = state.home;

  const {
    orderReceiving,
  } = state.common;

  const {
    photo_data
  } = state.photo_list;

  return {
    currentPage : currentPage,
    order_receiving : orderReceiving,
    photo_data : photo_data
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            sendOrderMail,
            setPage,
            setPhotoData
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;