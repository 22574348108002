import React from "react";
import Login from "../../components/public/Login";

class LoginContainer extends React.Component {
  render() {
    return <Login/>;
  }
}

export default LoginContainer;
