import React, {useEffect } from 'react'

const OrderDetails = (props) => {
  const { order_details,
          callUseCaseList,
          use_case_list,
          handleSelectedActivity,
          handleSelectedUseCase,
          selected_use_case
        } = props;

  useEffect(() => {
    callUseCaseList();
  },[callUseCaseList]);

  const handleUseCase = (e,currentUseCase) => {
    const {checked} = e.target;
    checked ? handleSelectedUseCase(currentUseCase) : handleSelectedUseCase(null);
  }

  const handleActivity = (currentActivity) => {
    handleSelectedActivity(currentActivity);
  }

  return (
    <section className="text-white px-3 pb-5 space-y-2">
      {order_details && (
        <>
          <div className="flex flex-col space-y-2 bg-[#303030] px-2 py-1 rounded-md">
            <div className="flex justify-between ">
              <div className="flex flex-col">
                <label className="text-sm md:text-xl lg:text-xl text-gray-400">
                  Sales Order #
                </label>
                <span className="text-xs md:text-xl lg:text-xl">{order_details.SalesOrderNo}</span>
              </div>
              <div className="flex flex-col">
                <label className="text-sm md:text-xl lg:text-xl text-gray-400">Customer</label>
                <span className="text-xs md:text-xl lg:text-xl">{order_details.CustomerName}</span>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <label className="text-sm md:text-xl lg:text-xl text-gray-400">Customer Email</label>
                <span className="text-xs md:text-xl lg:text-xl">{order_details.CustomerEmail}</span>
              </div>
              <div className="flex flex-col">
                <label className="text-sm md:text-xl lg:text-xl text-gray-400">Customer #</label>
                <span className="text-xs md:text-xl lg:text-xl">{order_details.CustomerNo}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2 bg-[#303030] px-2 py-1 rounded-md">
            <div className="flex justify-between ">
              <div className="flex flex-col">
                <label className="text-sm md:text-xl lg:text-xl text-gray-400">Shipping Date</label>
                <span className="text-xs md:text-xl lg:text-xl">{order_details.ShippingDate}</span>
              </div>
              <div className="flex flex-col">
                <label className="text-sm md:text-xl lg:text-xl text-gray-400">Shipping From</label>
                <span className="text-xs md:text-xl lg:text-xl">{order_details.ShippingFrom}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2 bg-[#303030] px-2 py-1 rounded-md">
            <div className="flex justify-between ">
              <div className="flex flex-col">
                <label className="text-sm md:text-xl lg:text-xl text-gray-400">Shipping Agent</label>
                <span className="text-xs md:text-xl lg:text-xl">{order_details.ShippingAgent}</span>
              </div>
              <div className="flex flex-col">
                <label className="text-sm md:text-xl lg:text-xl text-gray-400">Shipping Method</label>
                <span className="text-xs md:text-xl lg:text-xl">{order_details.ShippingMethod}</span>
              </div>
            </div>
          </div>
        </>
      )}

      {/* UseCase Listing */}
      <div className="grid grid-cols-2 gap-4">
        {use_case_list.map((use_case, idx) => {
          return (
            <div className="flex flex-col" key={idx}>
              <div>
                <label
                  className={`block border-red-700 border-2 rounded-md text-center cursor-pointer text-sm md:text-xl lg:text-xl font-medium select-none py-3 ${
                    selected_use_case === use_case.id ? "bg-red-700" : ""
                  }`}
                  htmlFor={idx}
                >
                  {use_case.name}
                </label>
                <input
                  type="checkbox"
                  className="hidden"
                  checked={selected_use_case === use_case.id}
                  id={idx}
                  onChange={(e) => handleUseCase(e, use_case.id)}
                />
              </div>
              {selected_use_case === use_case.id && (
                <div className="mt-2 space-y-2">
                  {use_case.act_usecases.map((activity, actIdx) => {
                    return (
                      <div
                        className="text-start py-1 border-2 rounded-lg border-red-700 text-sm md:text-xl lg:text-xl px-2 select-none cursor-pointer"
                        key={actIdx}
                        onClick={() => handleActivity(activity)}
                      >
                        <span>{activity.name}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default OrderDetails;
