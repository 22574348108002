import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { get } from "lodash-es";

const Gallery = (props) => {
  const {
    imgGallery,
    removeGalleryImg,
    uploadFile,
    handleImageLoading,
    is_image_loading,
    handleCreatePhotoList,
    order_receiving,
    handleResetData,
    updateBc
    } = props;
  const navigate = useNavigate();

  const removeImg = (idx) => {
    removeGalleryImg(idx);
  };

  const base64ToFile = async (base64String, filename) => {
    const [header, data] = base64String.split(',');
    const mimeType = header.split(':')[1].split(';')[0];
    const byteString = atob(data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeType });
    const file = new File([blob], filename, { type: mimeType });
    return file;
  }

  const handleImageUpload = async () => {
    
    let date = new Date()
    let currentDate = date.toLocaleDateString().replaceAll('/','_');
    let time = `${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;
    const dateFormat = `${currentDate}_${time}`;
    imgGallery.map(async (img,idx) => {
        const {id,data,is_uploaded} = img;
        if (!is_uploaded){
            handleImageLoading(true);
            const imgFile = await base64ToFile(data,`${id}.jpeg`);
            await uploadFile(imgFile,dateFormat);
            // if ((imgGallery.length - 1) === idx){
            //     handleImageLoading(false);
            // }
        }
    });
  }

  useEffect(() => {
    const filterUpdatedImg = imgGallery.filter(img => img.is_uploaded === false);
    if (filterUpdatedImg.length === 0 && is_image_loading) {
        const {
            order_details,
            photoLink,
            selectedActivity,
            selectedUseCase,
            referenceNo,
            bc_update
        } = order_receiving;
        const payload = {
            "ref_no" : get(order_details,"SalesOrderNo",referenceNo),
            "customer_no": get(order_details,"CustomerNo",""),
            "customer_name": get(order_details,"CustomerName",""),
            "customer_email": get(order_details,"CustomerEmail",""),
            "created_by" : get(order_details,"CreatedBy",""),
            "external_document_no" : get(order_details,"ExternalDocumentNo",""),
            "use_case": selectedUseCase,
            "activity_id": selectedActivity,
            "photo_url": photoLink,
            "bc_update_status": 0
        }
        const handlePostRequest = async () => {
            if (bc_update){
                let updateBcRes = await updateBc();
                payload.bc_update_status = updateBcRes ? 1 : 2;
            }
            else {
                payload.bc_update_status = 0;
            }
            let res = await handleCreatePhotoList(payload);
            handleImageLoading(false);
            if (!res.error){
                await handleResetData();
                navigate('/photo-list');
            }
        }
        handlePostRequest();
    }
  },[imgGallery,navigate,handleCreatePhotoList,order_receiving,handleImageLoading,is_image_loading,handleResetData,updateBc]);

  return (
    <div className="px-4 pb-4 h-full flex flex-1 flex-col">
      <div className="w-full bg-[#303030] rounded-lg py-2 px-3 flex-1 overflow-y-auto">
        {imgGallery.length > 0 ? (
          <div className="grid lg:grid-cols-4 grid-cols-3 gap-4 md:gap-6 mt-2">
            {imgGallery.map((img, idx) => {
              return (
                <div
                  key={idx}
                  className="w-full h-[4.5rem] sm:h-[4.5rem] md:h-32 lg:h-[4.5rem] xl:h-[4.5rem] bg-black rounded-2xl text-white relative shadow-md"
                >
                  <img
                    src={img.data}
                    className="w-full h-full object-contain object-center rounded-2xl"
                    alt=""
                  />
                  {!img.is_uploaded ? (
                    <svg
                      onClick={
                        !is_image_loading ? () => removeImg(img.id) : undefined
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      className={`size-7 sm:size-7 md:size-12 lg:size-7 xl:size-7 absolute -right-2 sm:-right-2 lg:-right-2 xl:-right-2  md:-right-1 -top-3 sm:-top-3 md:-top-4 lg:-top-3 xl:-top-3 ${
                        is_image_loading
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="size-7 sm:size-7 md:size-12 lg:size-7 xl:size-7 -right-2 sm:-right-2 lg:-right-2 xl:-right-2  md:-right-1 -top-3 sm:-top-3 md:-top-4 lg:-top-3 xl:-top-3 absolute text-white fill-green-700"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-center text-white font-semibold text-sm md:text-2xl lg:text-sm">
            No Gallery Image
          </p>
        )}
      </div>
      <div className="flex justify-center items-center mt-4 md:mt-8 space-x-5">
        {imgGallery.length > 0 && (
          <button
            className={`inline-flex items-center text-white bg-red-600 px-3 py-3 text-sm md:text-2xl lg:text-sm rounded-lg ${
              is_image_loading ? "cursor-not-allowed" : ""
            } `}
            disabled={is_image_loading ? true : false}
            onClick={!is_image_loading ? handleImageUpload : undefined}
          >
            {is_image_loading && (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            {!is_image_loading ? "Upload" : "Uploading..."}
          </button>
        )}
        <Link
          to="/camera"
          className={`text-white bg-gray-500 px-2 py-1 rounded-lg ${is_image_loading ? "cursor-not-allowed pointer-events-none" : ""}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className={`size-10 md:size-14 sm:size-10 lg:size-10 xl:size-10 ${is_image_loading ? "cursor-not-allowed pointer-events-none" : ""}`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}

export default Gallery;