import React, { Component } from 'react'
import Scan from '../../components/home/Scan';
import Receiving from '../../components/home/Receiving';
import { INTERNAL_REC } from '../../store/home/homeConstants';
import SalesOrder from '../../components/home/SalesOrder';

export default class HomeContainer extends Component {
    componentDidMount(){
        this.props.getHomeList(INTERNAL_REC);
    }

    handleOrderReset = () => {
        this.props.resetOrderDetails();
        this.props.setOrderDetails(null);
    }

    handleSubmit = async (values) => {
        let requestBody = values;
        delete requestBody.activity_id;
        let res = await this.props.getOrderReturnDetails(requestBody);
        return res
    };

    handleCurrentPage = (newPage) => {
        this.props.setScanType(2);
        this.props.setPage(newPage);
    };

    handleReceiving = (selected_activity,refNo) => {
        this.props.setReferenceNo(refNo);
        this.props.setSelectedActivity(selected_activity);
        this.props.setSelectedUseCase(3);
    };

    handleOrderDetails = (order_details) => {
        this.props.setOrderDetails(order_details);
    };

    render() {
        const {use_cases_details,orderReceiving,is_home_loading} = this.props;
        const {order_details} = orderReceiving;
        return (
            <section className='flex flex-col lg:px-50 md:px-24 px-5 pb-5'>
                <div className='text-center text-gray-400 flex-1'>
                    <Scan/>
                    <p className='lg:text-sm md:text-3xl text-sm lg:mb-1 md:mb-4 mb-1'>OR</p>
                    <SalesOrder handleOrderDetails = {this.handleOrderDetails}/>
                    <hr/>
                    {/* Receiving Section */}
                    <Receiving
                        use_cases_details = {use_cases_details}
                        getOrderDetails = {this.handleSubmit}
                        order_details = {order_details}
                        handleOrderReset = {this.handleOrderReset}
                        handleCurrentPage = {this.handleCurrentPage}
                        handleReceiving = {this.handleReceiving}
                        handleOrderDetails = {this.handleOrderDetails}
                    />
                    
                </div>
                <footer className='text-gray-400 text-center lg:mt-2 md:mt-10 mt-4 lg:text-sm md:text-2xl text-sm'>Powered by Addnectar Solutions Inc.</footer>
                {
                    (is_home_loading) &&
                    <div className='fixed left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.6)] overflow-hidden z-50 select-none flex justify-center items-center'>
                        <img className='w-16' src='/images/orbus-loader.gif' alt='orbus loader'/>
                    </div>
                }
            </section>
        )
    }
}
